
import { IonContent, IonHeader, IonPage,
  IonGrid, IonRow, IonCol,
  IonImg,IonButton,
  IonCard, IonCardTitle, IonCardHeader, IonCardContent, 
  IonItem, IonLabel, IonThumbnail,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import BalanceChart from '../components/BalanceChart.vue'
import ParisChart from '../components/ParisChart.vue'
//import InfoChart from '../components/InfoChart.vue'
import GeoChart from '../components/GeoChart.vue'
import ParticipantChart from '../components/ParticipantChart.vue'

//import axios from 'axios'

import { getPlatforms } from '@ionic/vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonGrid, IonRow, IonCol,
    IonImg,IonButton,
    IonCard, IonCardTitle, IonCardHeader, IonCardContent, 
    BalanceChart,
    ParisChart,
    //InfoChart,
    GeoChart,
    ParticipantChart,
    IonItem, IonLabel, IonThumbnail,

  },
  data () {
    return {
    }
  },
  methods: {
    balanceUpdated(){
      console.log("Home: Balance update")
      //this.updated++
    },
  },
  setup () {
    const updated = ref(0)
    const plt = getPlatforms(); // returns ["iphone", "ios", "mobile", "mobileweb"] from an iPhone
    console.log("Platform: ",plt)
    return { updated }
  }

});
